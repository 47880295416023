import React from "react";
import ImageFleurs from "../Images/Fleurs";
import MonStyle from "./PiedDePage.module.css";
import MentionsLegales from "./MentionsLegales";

export default function PiedDePage() {
  return (
    <div className={MonStyle.PiedDePage}>
      <div>
        <ImageFleurs />
      </div>
      <div className={MonStyle.Footer}>
        <div>Site réalisé par l'Atelier du Pixel</div>
        <MentionsLegales />
      </div>
    </div>
  );
}
