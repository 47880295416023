import React from "react";
import MonStyle from "./PageTarifs.module.css";

export default function PageTarifs() {
  return (
    <div className={MonStyle.PageTarifs} id="tarifs">
      <div className={MonStyle.Liste}>
        <h2 style={{ marginBottom: "40px", textAlign: "center" }}>
          Tarifs & services
        </h2>
        <div className={MonStyle.Ligne}>
          <div className={MonStyle.Service}>
            Chambre (petit déjeuner + taxe de séjour compris )
          </div>
          <div className={MonStyle.Tarif}>80 €</div>
        </div>
        <div className={MonStyle.Ligne}>
          <div className={MonStyle.Service}>Couchage d'appoint</div>
          <div className={MonStyle.Tarif}>10 € / personne</div>
        </div>
        <div className={MonStyle.Ligne}>
          <div className={MonStyle.Service}>Diner (Table d'hôtes)</div>
          <div className={MonStyle.Tarif}>20 € / personne</div>
        </div>
        <div className={MonStyle.Ligne}>
          <div className={MonStyle.Service}>Panier repas à emporter</div>
          <div className={MonStyle.Tarif}>10 € / personne</div>
        </div>
        <div className={MonStyle.Ligne}>
          <div className={MonStyle.Service}>Nettoyage linge</div>
          <div className={MonStyle.Tarif}>10 €</div>
        </div>
      </div>
      <div className={MonStyle.ModesAcceptes}>
        Modes de règlement acceptés : Espèces, chèques, paypal
      </div>
    </div>
  );
}
