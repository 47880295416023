import React from "react";
import ObjetPrestation from "../Design/Objets/ObjetPrestation";
import IconeSalleDeBain from "../Design/Icones/Icones";
import {
  IconeAnimaux,
  IconePetitDejeuner,
  IconeWifi,
  IconeTableHote,
  IconeVelo,
  IconeLessive,
} from "../Design/Icones/Icones";

export default function BlocPrestations() {
  return (
    <div
      style={{
        backgroundColor: "#66858A10",
        color: "#66858A",
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        flexWrap: "wrap",
        gap: "20px",
        alignItems: "flex-start",
      }}
    >
      <ObjetPrestation
        icone={<IconeSalleDeBain />}
        nom="Salle de bain"
        description="Privée"
        to="#salle-de-bain"
      />
      <ObjetPrestation
        icone={<IconePetitDejeuner />}
        nom="Petit Déjeuner"
        description="Inclus"
        to="#petit-dejeuner"
      />
      <ObjetPrestation
        icone={<IconeWifi />}
        nom="Wifi"
        description="Inclus"
        to="#chambre"
      />
      <ObjetPrestation
        icone={<IconeTableHote />}
        nom="Table d'hôte"
        description="Sur réservation"
        to="#table-hotes"
      />
      <ObjetPrestation
        icone={<IconeVelo />}
        nom="Parking Vélo"
        description="Fermé"
        to="#velos"
      />
      <ObjetPrestation
        icone={<IconeAnimaux />}
        nom="Animaux"
        description="Bienvenus"
        to="#animaux"
      />
      <ObjetPrestation
        icone={<IconeLessive />}
        nom="Nettoyage"
        description="Vêtements"
        to="#nettoyage"
      />
    </div>
  );
}
