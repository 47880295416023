import React from "react";
import GallerieChambre from "../Components/GalerieChambre";
import MonStyle from "./PageChambre.module.css";

export default function PageChambre() {
  return (
    <div className={MonStyle.PageChambre} id="chambre">
      <div className={MonStyle.Description}>
        <h3>La chambre</h3>
        <p>
          <b>La chambre est non fumeur.</b> Elle est équipée d'un lit double et
          d'un bureau. La salle de bain ainsi que les toilettes sont privatifs.
          Pour agrémenter votre séjour, vous disposez d'une bouilloire et d'une
          cafetière.
        </p>
        <p>
          Au besoin, nous pouvons installer un matelas d'appoint pour un adulte
          (ou deux enfants).
        </p>
        <p>
          Au calme, dans une partie indépendante de la maison, elle dispose d'un
          accès sur le jardin, pour flâner, y prendre le petit déjeuner (ou
          m'aider à désherber !)
        </p>
      </div>
      <div className={MonStyle.Galerie}>
        <GallerieChambre />
      </div>
    </div>
  );
}
