import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Photo({ src, hideBorder, style, texte }) {
  //NES gestion du state
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div
      style={{
        maxWidth: "100vw",
        border: hideBorder ? "" : "11px solid #ffffff",
        boxShadow: "8px 8px 20px 0 rgba(0, 0, 0, 0.2)",
        boxSizing: "border-box",
        height: "auto",
        cursor: "pointer",
        overflow: "hidden",
        ...style,
      }}
    >
      <img
        onClick={handleOpen}
        src={src}
        alt=""
        style={{
          width: "100%",
          height: "100%",
          display: "block",
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,
            outline: 0,
            color: "white",
            border: "11px solid #ffffff",
            maxWidth: "90vw",
            width: "1200px",
            height: "auto",
          }}
        >
          <IconButton
            aria-label="delete"
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              backgroundColor: "#00000090",
            }}
            onClick={handleClose}
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
          <img
            onClick={handleOpen}
            src={src}
            alt=""
            style={{
              width: "100%",
              maxWidth: "1200px",
              height: "auto",
              maxHeight: "100vh",
              display: "block",
            }}
          />

          <div
            style={{
              backgroundColor: "#000000",
              color: "white",
              padding: "15px",
              opacity: 0.7,
              position: "absolute",
              bottom: "0px",
            }}
          >
            {texte}
          </div>
        </div>
      </Modal>
    </div>
  );
}
