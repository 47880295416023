import { ButtonBase } from "@mui/material";
import React from "react";
import MonStyle from "./BarreNavigation.module.css";
import { HashLink as Link } from "react-router-hash-link";
import BoutonDisponiblites from "../Design/Boutons/BoutonDisponibilites";

export default function BarreNavigation() {
  return (
    <div className={MonStyle.BarreNavigation}>
      <nav className={MonStyle.Liens}>
        <Link smooth to="#chambre">
          La chambre
        </Link>
        <Link smooth to="#salle-de-bain">
          Salle de bain
        </Link>
        <Link smooth to="#petit-dejeuner">
          Petit déjeuner
        </Link>
        <Link smooth to="#table-hotes">
          Table d'hôtes
        </Link>
        <Link smooth to="#velos">
          Vélos
        </Link>
        <Link smooth to="#animaux">
          Animaux
        </Link>
        <Link smooth to="#tarifs">
          Tarifs
        </Link>
      </nav>
      <BoutonDisponiblites />
    </div>
  );
}
