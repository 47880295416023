import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function MentionsLegales({ props }) {
  //NES gestion du state
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div onClick={handleOpen} style={{ cursor: "pointer" }}>
        Mention légales
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,
            outline: 0,
            color: "white",
            border: "11px solid #ffffff",
            maxWidth: "90vw",
            width: "1200px",
            height: "auto",
            backgroundColor: "white",
            padding: "40px",
            boxSizing: "border-box",
          }}
        >
          <IconButton
            aria-label="delete"
            style={{
              position: "absolute",
              top: "20px",
              left: "20px",
              backgroundColor: "#00000090",
            }}
            onClick={handleClose}
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>

          <h1 style={{ textAlign: "center" }}>Mentions légales</h1>
          <h2>Editeur du site</h2>
          <p>
            Le Site est détenu par la société l'Atelier du Pixel, société par
            actions simplifiée au capital de 14.000 euros, dont le siège social
            est à Liverdun, 10 rue porte haute, immatriculée au registre du
            commerce et des sociétés de Nancy sous le numéro 901 628 370 RCS
            Nancy. L'Atelier du Pixel agit pour le compte de M ESSE Nicolas.
          </p>
          <h2>Propriétaire du texte et photos</h2>
          <p>
            L’ensemble des éléments du contenu du Site (textes, images, données,
            dessins, graphiques, photos et bandes sonores…) et l’ensemble des
            éléments relatifs à sa forme (choix, plan, disposition des matières,
            moyens d’accès aux données, organisation des données…) sont la
            propriété entière et exclusive de l'Atelier du Pixel ou de ses
            partenaires. Toute reproduction, représentation, adaptation,
            utilisation ou modification, par quelque procédé que ce soit et sur
            quelque support que ce soit, de tout ou partie du Site, de tout ou
            partie des différentes œuvres qui le composent, sans avoir obtenu
            l’autorisation préalable expresse et écrite de L'Atelier du Pixel,
            est strictement interdite et constitue un délit de contrefaçon
            sanctionné par les articles L. 335-2 et suivants du code de la
            propriété intellectuelle. Par exception, certains contenus (textes,
            images) sont la propriété de leurs auteurs respectifs.
          </p>
          <h2>Contact</h2>
          <p>
            M ESSE Nicolas <br />
            07 68 12 57 25
            <br />
            nicolas.esse@gmail.com
            <br />
            10 rue porte haute 54460 LIVERDUN
            <br />
          </p>
          <h2>Hébergeur</h2>
          <p>
            Le site est hébergé par la société Amazon Web Services: <br />
            Amazon Web Services LLC P.O. Box 81226 Seattle, WA 98108-1226
            <br />
            http://aws.amazon.com
          </p>
        </div>
      </Modal>
    </div>
  );
}
