import React from "react";
import BlocPrestations from "../Components/BlocPrestations";
import PhotosHome from "../Components/PhotosHome";
import MonStyle from "./PageHome.module.css";

export default function PageHome() {
  return (
    <div className={MonStyle.PageHome}>
      <div className={MonStyle.BlocHome}>
        <div className={MonStyle.Photo}>
          <PhotosHome />
        </div>
        <div className={MonStyle.TexteAccueil}>
          <h1>Villa Bel Amour</h1>
          <h2>Chambre d'hôte</h2>
          <p>
            Chers voyageurs, c'est avec plaisir que nous vous accueillerons à
            Liverdun dans notre chambre d'hôte. La maison est une villa du
            XIXeme siècle située face à la chapelle Bel Amour et offre une vue
            imprenable sur les boucles de la Moselle. Elle est proche de
            l'office du tourisme dans le village médiéval.
          </p>
          <p>
            C'est avec plaisir que nous échangerons ensemble sur les lieux à
            visiter, les promenades et les spécialités locales.
          </p>
          <p className="Signature">Nicolas</p>
        </div>
      </div>
      <BlocPrestations />
    </div>
  );
}
