import React from "react";
import Photo from "../../Components/Photo";

export default function ObjetService({
  id,
  titre,
  src,
  rotation,
  description,
}) {
  return (
    <div
      id={id}
      style={{ position: "relative", maxWidth: "320px", paddingTop: "40px" }}
    >
      <h4
        style={{
          backgroundColor: "#FFFFFF",
          boxShadow: "2px 2px 10px 0 rgba(0, 0, 0, 0.2)",
          width: "fit-content",
          padding: "5px 20px",
          position: "absolute",
          top: "-30px",
          left: "50%",
          transform: `rotate(${rotation}deg) translate(-50%)`,
          opacity: 0.8,
        }}
      >
        {titre}
      </h4>
      <div>
        <Photo src={src} />
      </div>
      <p>{description}</p>
    </div>
  );
}
