import React from "react";
import Photo from "./Photo";
import PhotoChambre1 from "../Images/PhotoChambre1.jpg";
import PhotoChambre2 from "../Images/PhotoChambre2.jpg";
import PhotoChambre3 from "../Images/PhotoChambre3.jpg";
import PhotoJardin from "../Images/PhotoJardin.jpg";
import PhotoThe from "../Images/PhotoThe.jpg";
import PhotoCafe from "../Images/PhotoCafe.jpg";

export default function GallerieChambre() {
  return (
    <div
      style={{
        maxWidth: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "1fr 1fr 1fr",
        boxSizing: "border-box",
        gap: "10px",
      }}
    >
      <Photo
        hideBorder
        src={PhotoChambre1}
        texte="Le lit et la vue sur le jardin"
        style={{
          gridColumn: "1 / span 2",
          gridRow: "1/span 2",
          borderRadius: "20px 0px 0px 0px",
        }}
      />
      <Photo
        hideBorder
        src={PhotoThe}
        texte="C'est l'heure du thé"
        style={{
          gridColumn: "3 ",
          gridRow: 1,
          borderRadius: "0px 20px 0px 0px",
        }}
      />
      <Photo
        hideBorder
        src={PhotoChambre2}
        texte="Une autre vue de la chambre et du bureau"
        style={{ gridColumn: "3", gridRow: 2 }}
      />
      <Photo
        hideBorder
        src={PhotoCafe}
        texte="Meilleur est le café, meilleures sont les idées"
        style={{
          gridColumn: "1",
          gridRow: 3,
          borderRadius: "0px 0px 0px 20px",
        }}
      />
      <Photo
        texte="Le couchage d'appoint"
        hideBorder
        src={PhotoChambre3}
        style={{ gridColumn: "2", gridRow: 3 }}
      />
      <Photo
        hideBorder
        texte="Le jardin devant la chambre"
        src={PhotoJardin}
        style={{
          gridColumn: "3",
          gridRow: 3,
          borderRadius: "0px 0px 20px 0px",
        }}
      />
    </div>
  );
}
