import React from "react";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MonStyle from "./Entete.module.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
export default function Entete() {
  const styleBouton = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    color: "white",
  };

  return (
    <div className={MonStyle.Entete}>
      <a
        style={styleBouton}
        href="http://maps.google.com/?q=Rue Porte Haute 10, Liverdun"
        target="_blank"
      >
        <LocationOnIcon />
        10 rue porte haute 54460 Liverdun
      </a>

      <a style={styleBouton} href="tel:+33768125725">
        <PhoneIphoneIcon />
        07 68 12 57 25
      </a>
    </div>
  );
}
