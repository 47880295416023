import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default function BoutonDisponiblites() {
  return (
    <Link
      smooth
      to="#disponibilites"
      style={{
        fontFamily: "Roboto",
        fontSize: "18px",
        height: "50px",
        color: "white",
        backgroundColor: "#208AB0",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 25px",
        borderRadius: "50px",
        cursor: "pointer",
      }}
    >
      Voir les disponiblités
    </Link>
  );
}
