import React from "react";
import Calendrier from "../Components/Calendrier";
import MonStyle from "./PageDisponibilites.module.css";
import BoutonContact from "../Design/Boutons/BoutonContact";

export default function PageDisponiblites() {
  const MOIS_AFFICHES = [6, 7, 8];

  const DATE_RESERVES = [
    //JUILLET
    "05/07/2022",
    "08/07/2022",
    "11/07/2022",
    "12/07/2022",
    "13/07/2022",
    "22/07/2022",
    "23/07/2022",
    "24/07/2022",

    //AOUT
    "02/08/2022",
    "03/08/2022",
    "04/08/2022",
    "05/08/2022",

    "06/08/2022",
    "07/08/2022",
    "08/08/2022",
    "09/08/2022",
    "10/08/2022",
    "11/08/2022",
    "12/08/2022",
    "13/08/2022",
    "14/08/2022",
    "15/08/2022",
    "16/08/2022",
    "17/08/2022",
    "18/08/2022",
    "19/08/2022",
    "20/08/2022",
    "21/08/2022",

    //SEPTEMBRE
    "24/09/2022",
  ];

  return (
    <div className={MonStyle.PageDisponibilites} id="disponibilites">
      <div className={MonStyle.BlocTexte}>
        <h4>Disponibilites</h4>
        <p style={{ color: "#2b5a3c" }}>
          Nous affichons les{" "}
          <b>disponiblités sur les prochains mois à titre indicatif</b>.
          N'hésitez pas à nous contacter pour préparer votre voyage.
        </p>
        <div className={MonStyle.TexteReservation}>
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            La réservation se fait par téléphone.
          </div>
          <div>Contact : Nicolas Esse </div>
        </div>

        <BoutonContact />
      </div>
      <div className={MonStyle.BlocCalendriers}>
        {MOIS_AFFICHES.map((numeroMois) => (
          <Calendrier numeroMois={numeroMois} dateReserves={DATE_RESERVES} />
        ))}
      </div>
    </div>
  );
}
