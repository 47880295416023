import React, { useEffect, useState } from "react";
import MonStyle from "./Calendrier.module.css";

export default function Calendrier({ numeroMois, dateReserves }) {
  //NES Gestion du state
  const [jours, setJours] = useState([]);
  const [nomMois, setNomMois] = useState("");

  const HEURE_LIMITE = 19;

  useEffect(() => {
    let MaDate = new Date();
    let premierJour = new Date();

    if (numeroMois) {
      MaDate.setMonth(numeroMois);
      premierJour = MaDate;
      premierJour.setDate(1);
    }

    //NES nom du mois
    setNomMois(MaDate.toLocaleDateString("fr-Fr", { month: "long" }));

    let moisDate = premierJour.getMonth();
    let moisCourant = moisDate;
    let joursDuMois = [];

    //NES quel est le jour correspondant au 1er du mois (pour créer le décalage)

    let numeroJour = premierJour.getDay();
    //Dimanche = 0
    if (numeroJour === 0) {
      numeroJour = 6;
    } else numeroJour = numeroJour - 1;

    let joursVides = new Array(numeroJour === 7 ? 0 : numeroJour);
    joursVides.fill("");

    while (moisDate === moisCourant) {
      let jour = new Date(premierJour);
      joursDuMois.push(jour.getDate());
      premierJour.setDate(premierJour.getDate() + 1);
      moisCourant = premierJour.getMonth();
    }
    let tableauComplet = joursVides.concat(joursDuMois);
    setJours(tableauComplet);
  }, [numeroMois]);

  //console.log(premierJourDuMois.toLocaleDateString("fr-FR"));

  const getStyleJour = (jour) => {
    if (jour) {
      //NES est ce que la date est réservée?
      let dateCase = new Date();
      dateCase.setMonth(numeroMois);
      dateCase.setDate(jour);
      dateCase.setHours(HEURE_LIMITE);

      let dateEnString = dateCase.toLocaleString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      let aujourdhui = new Date();

      if (dateReserves.includes(dateEnString) || dateCase < aujourdhui) {
        return MonStyle.JourNonDisponible;
      }

      return MonStyle.JourDisponible;
    }
    return "";
  };

  return (
    <div className={MonStyle.Calendrier}>
      <h5>{nomMois}</h5>
      <div className={MonStyle.Grille}>
        <span className={MonStyle.NomJour}>L</span>
        <span className={MonStyle.NomJour}>M</span>
        <span className={MonStyle.NomJour}>M</span>
        <span className={MonStyle.NomJour}>J</span>
        <span className={MonStyle.NomJour}>V</span>
        <span className={MonStyle.NomJour}>S</span>
        <span className={MonStyle.NomJour}>D</span>
        {jours.map((jour, index) => (
          <span key={index} className={getStyleJour(jour)}>
            {jour}
          </span>
        ))}
      </div>
    </div>
  );
}
