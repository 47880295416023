import React from "react";
import MonStyle from "./PageServices.module.css";
import ObjetService from "../Design/Objets/ObjetService";
import PhotoSalleDeBain from "../Images/PhotoSalleDeBain.jpg";
import PhotoAnimaux from "../Images/PhotoAnimaux.jpg";
import PhotoVue from "../Images/PhotoVue.jpg";
import PhotoPetitDejeuner from "../Images/PhotoPetitDejeuner.jpg";
import PhotoApero from "../Images/PhotoTable.jpg";
import PhotoVelo from "../Images/PhotoParkingVelo.jpg";
import PhotoRandonne from "../Images/PhotoRandonnees.jpg";

export default function PageServices() {
  return (
    <div className={MonStyle.PageServices}>
      <ObjetService
        id="salle-de-bain"
        titre="Salle de bain"
        src={PhotoSalleDeBain}
        rotation={5}
        description={
          "Privative et attenante à la chambre, la salle de bain est spacieuse et lumineuse. Elle dispose d'une baignoire, d'une douche à l'italienne et d'un cabinet de toilettes. Le linge de toilette est fourni.  "
        }
      />
      <ObjetService
        id="petit-dejeuner"
        titre="Petit Déjeuner"
        src={PhotoPetitDejeuner}
        rotation={-5}
        description={
          "Nous serons ravis de vous composer un petit déjeuner maison, sucré ou salé en fonction de vos préférences. Il peut être servi à partir de 6h30 si vous souhaitez partir de bonne heure."
        }
      />
      <ObjetService
        id="table-hotes"
        titre="Table d'hôtes"
        src={PhotoApero}
        rotation={1}
        description={
          "Nous aimons cuisiner et nous serions ravis de partager le repas avec vous (A préciser lors de la réservation). Le repas complet est à 20€ par personne. Pensez à nous prévenir si vous avez des restrictions alimentaires."
        }
      />
      <ObjetService
        id="animaux"
        titre="Animaux"
        src={PhotoAnimaux}
        rotation={-1}
        description={
          "Les animaux sont les bienvenus mais sachez qu'il y a déjà un gentil chien et un chat câlin à la maison. Le terrain est fermé ce qui permet à nos amis à 4 pattes de se promener librement. "
        }
      />
      {/* 
      <ObjetService
        id="nettoyage"
        titre="Laver le linge"
        src={PhotoSalleDeBain}
        rotation={3}
        description={
          "Equipés d'un sèche linge, nous pouvons vous laver et sécher votre linge pour le lendemain matin. Ce service est facturé 10€."
        }
      />*/}
      <ObjetService
        id="petit-dejeuner"
        titre="Stocker les vélos"
        src={PhotoVelo}
        rotation={3}
        description={
          "Vous voyagez à vélo? Vous pouvez stationner vos vélos en toute confiance dans le jardin celui-ci étant clos et fermé. "
        }
      />
      <ObjetService
        titre="Se promener"
        src={PhotoRandonne}
        rotation={-3}
        description={
          "Plusieurs randonnées partent devant la maison. De 30 minutes à quelques heures, promenez-vous aux alentours de Liverdun. Pour les grands marcheurs, nous sommes à la croisée de Saint Jacques de Compostelle et du GR5. "
        }
      />
      <ObjetService
        titre="Profiter de la vue"
        src={PhotoVue}
        rotation={3}
        description={
          "Après une journée de marche, quoi de mieux que contempler le coucher de soleil depuis la porte Haute ou depuis la terrasse de la maison. "
        }
      />
    </div>
  );
}
