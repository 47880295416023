import React from "react";
import PhotoMaison from "../Images/PhotoMaison.jpg";
import PhotoCartePostale from "../Images/PhotoCartePostale.jpg";
import MonStyle from "./PhotosHome.module.css";

export default function PhotosHome() {
  return (
    <div className={MonStyle.PhotosHome}>
      <img src={PhotoMaison} className={MonStyle.PhotoMaison} />
      <img src={PhotoCartePostale} className={MonStyle.CartePostale} />
    </div>
  );
}
