import React from "react";
import Entete from "../Components/Entete";
import BarreNavigation from "../Components/BarreNavigation";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PageHome from "../Pages/PageHome";
import PageChambre from "../Pages/PageChambre";
import PageServices from "../Pages/PageServices";
import PageDisponiblites from "../Pages/PageDisponibilites";
import PageTarifs from "../Pages/PageTarifs";
import PiedDePage from "../Components/PiedDePage";
export default function RoutesPubliques() {
  return (
    <Router>
      <Entete />
      <BarreNavigation />
      <PageHome />
      <PageChambre />
      <PageServices />
      <PageTarifs />
      <PageDisponiblites />
      <PiedDePage />
    </Router>
  );
}
