import React from "react";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
export default function BoutonContact() {
  return (
    <a
      href="tel:+33768125725"
      style={{
        fontFamily: "Roboto",
        fontSize: "18px",
        height: "50px",
        color: "white",
        backgroundColor: "#208AB0",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 25px",
        borderRadius: "50px",
        cursor: "pointer",
        textAlign: "center",
        width: "fit-content",
        textDecoration: "none",
      }}
    >
      <PhoneIphoneIcon style={{ marginRight: "10px" }} />
      07 68 12 57 25
    </a>
  );
}
