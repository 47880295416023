import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default function ObjetPrestation({ to, icone, nom, description }) {
  return (
    <Link
      smooth
      to={to ? to : "/"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        minWidth: "100px",
      }}
    >
      {icone}
      <div style={{ fontWeight: "700", marginTop: "10px" }}>{nom}</div>
      <div className="Description"> {description}</div>
    </Link>
  );
}
